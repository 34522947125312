import React from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Product } from 'utils/helpers/product';

import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { useCardDimensions } from 'src/components/product-card/product-card.utils';
import { HybridCarousel, HybridCarouselCard } from 'src/components/hybrid-carousel';
import { CarouselStates } from 'src/components/hybrid-carousel/use-hybrid-carousel-rendered';
import { CarouselContainer } from 'src/components/hybrid-carousel/hybrid-carousel.styles';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';

import { useSponsoredBrandProductsV2, UseSponsoredBrandProductsParams } from './use-sponsored-brand-products-v2';
import { useCarouselTheme } from '../../carousels.utils';
import { CarouselViewAll } from '../../carousel-view-all';

type SponsoredBrandCarouselProps = UseSponsoredBrandProductsParams & {
  fullWidth?: boolean;
  onRender?: (state: CarouselStates) => void;
  ref?: React.Ref<HTMLDivElement>;
};

export const SponsoredBrandCarouselV2 = ({
  fullWidth = false,
  onRender,
  sponsoredBrandQuery,
}: SponsoredBrandCarouselProps): JSX.Element | null => {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;
  const { dispensary } = useDispensary();
  const { href } = usePaths({ brands: true });
  const carouselTheme = useCarouselTheme(dispensary);
  const dimensions = useCardDimensions({ gutter: 12 });

  const {
    error,
    loading,
    products,
    handleProductClick,
    handleQuickAddToCart,
    handleViewAllClick,
    viewportVisibility,
    handleProductImpression,
    brandSlug,
  } = useSponsoredBrandProductsV2({ sponsoredBrandQuery });

  if (!products.length || error) {
    return null;
  }

  if (!brandSlug) {
    console.error('missing brandSlug');
    return null;
  }
  
  const brandHref = `${href}/${brandSlug}`;

  if (isProductCardsV2Enabled) {
    return (
      // This extra div is just here to account for a child selector removing padding. Once we clean up this flag the div can be removed
      <div data-testid='sponsored-brand-carousel'>
        <Wrapper $bgColor={carouselTheme.backgroundColor}>
          <ProductCarousel
            ref={viewportVisibility.ref}
            products={products}
            isLoading={loading}
            title='Featured Brand'
            subTitle='Sponsored'
            href={brandHref}
            mt='0'
            gradientColor={carouselTheme.backgroundColor}
            onViewAllClick={handleViewAllClick}
            onProductClick={handleProductClick}
            onQuickAddToCart={handleQuickAddToCart}
            onProductView={handleProductImpression}
            hideSponsoredTag
            isBottomSubTitle
          />
        </Wrapper>
      </div>
    );
  }

  const carouselProps = {
    heading: 'Featured Brand',
    subheading: 'Sponsored',
    href: brandHref,
    linkLabel: 'View all',
    loading,
    fullWidth,
    carouselTheme,
    dimensions,
    viewportVisibility,
  };

  return (
    <StyledCarouselContainer {...carouselTheme} data-testid='sponsored-brand-carousel' fullWidth={fullWidth}>
      <HybridCarousel {...carouselProps} onClickHeaderLink={handleViewAllClick} onRender={onRender}>
        {products.map((product: Product, index) => (
          <HybridCarouselCard
            carouselTheme={carouselTheme}
            dimensions={dimensions}
            key={product.id}
            onClick={() => handleProductClick(product, index)}
            onQuickAddClick={() => handleQuickAddToCart(product, index)}
            onView={() => handleProductImpression(product)}
            product={product}
            productIndex={index}
            filledQuickAdd
            hideSponsoredTags
          />
        ))}
        <CarouselViewAll href={brandHref} />
      </HybridCarousel>
    </StyledCarouselContainer>
  );
};

const StyledCarouselContainer = styled(CarouselContainer)`
  margin: 0 auto;
  padding: 4px 0;
`;

const Wrapper = styled.div`
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  padding: 20px 20px 0;
`;
