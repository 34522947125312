import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import useDispensaryMenuSections from 'src/dispensary/hooks/use-dispensary-menu-sections';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import { useRecentlyViewedCarouselEnabled } from 'src/utils/personalization/hooks/use-personalization-enabled';

import { CollectionCarousel as CollectionItemsSection } from 'src/components/carousels/products-carousels/collection-carousel';
import {
  BrandCarousel as BrandSection,
  CategoryCarousel as CategorySection,
  SpecialsCarousel as SpecialsSection,
  SubcategoryCarousel as SubcategorySection,
  StaffPicksCarousel as StaffPicksSection,
  TopSellersCarousel as TopSellersSection,
  MostPurchasedCarousel as MostPurchasedSection,
} from '../carousels/generic-carousels';

import { CustomCarousel as CustomSection } from '../carousels/custom-carousels';
import { SpecialOffersCarousel as OffersSection } from '../carousels/offers-carousels';
import { RecentlyViewedCarousel as RecentlyViewedSection } from '../carousels/products-carousels';

const CAROUSELS_PER_BATCH = 3;

// TODO: When we upgrade to React 18, we should use the new scheduler API to schedule the rendering of the carousels.
const RENDER_DELAY = 100; // ms

const CarouselSection = React.memo(({ section, index, dispensaryState, hideSpecials, showRecentlyViewedCarousel }) => {
  switch (section.sectionType) {
    case 'BRAND':
      return <BrandSection key={section.id} section={section} sectionIndex={index} />;
    case 'CATEGORY':
      return (
        <CategorySection key={section.id} section={section} sectionIndex={index} dispensaryState={dispensaryState} />
      );
    case 'RECENTLY_VIEWED':
      return (
        showRecentlyViewedCarousel && <RecentlyViewedSection key={section.id} section={section} sectionIndex={index} />
      );
    case 'SPECIALS':
      return !hideSpecials && <SpecialsSection key={section.id} section={section} sectionIndex={index} />;
    case 'SUBCATEGORY':
      return (
        <SubcategorySection key={section.id} section={section} sectionIndex={index} dispensaryState={dispensaryState} />
      );
    case 'STAFF_PICKS':
      return <StaffPicksSection key={section.id} section={section} sectionIndex={index} />;
    case 'TOP_SELLERS':
      return <TopSellersSection key={section.id} section={section} sectionIndex={index} />;
    case 'CUSTOM':
      return <CustomSection key={section.id} section={section} sectionIndex={index} />;
    case 'OFFERS':
      return <OffersSection key={section.id} section={section} />;
    case 'COLLECTION':
      return <CollectionItemsSection key={section.id} section={section} sectionIndex={index} />;
    default:
      return null;
  }
});

CarouselSection.displayName = 'CarouselSection';

export const HomepageCarouselSections = observer(({ dispensary }) => {
  const router = useRouter();
  const flags = useFlags();
  const dispensaryId = dispensary?.id;
  const { isLoggedIn } = useUser();
  const { isKiosk } = useUI();

  const personalizationForYouPlacement = flags['growth.ads.personalization-carousel.placement'];
  const personalizationForYouEnabled = personalizationForYouPlacement !== 'none';
  const personalizationBuyItAgainEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];
  const personalizationRecentlyViewedEnabled = useRecentlyViewedCarouselEnabled();
  const showRecentlyViewedCarousel = !isKiosk && personalizationRecentlyViewedEnabled && isLoggedIn;

  const showMostPurchasedProducts =
    isLoggedIn && !isKiosk && !(personalizationForYouEnabled && personalizationBuyItAgainEnabled);
  const { menuSections } = useDispensaryMenuSections();

  const [visibleSections, setVisibleSections] = useState(CAROUSELS_PER_BATCH);

  useEffect(() => {
    if (visibleSections < menuSections.length) {
      const timeoutId = setTimeout(() => {
        setVisibleSections((prev) => Math.min(prev + CAROUSELS_PER_BATCH, menuSections.length));
      }, RENDER_DELAY);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [visibleSections, menuSections.length]);

  if (!dispensaryId) {
    return null;
  }

  const { hideTitle } = router.query;
  const dispensaryState = dispensary.location?.state;
  const hideSpecials = useHideDiscountFromConsumer(dispensary);

  return (
    <Sections data-testid='homepage-carousel-sections'>
      {showMostPurchasedProducts && <MostPurchasedSection dispensaryId={dispensaryId} />}

      {_.map(menuSections.slice(0, visibleSections), (section, index) => {
        const sectionWithTitle = hideTitle ? { ...section, hideTitle } : section;
        return (
          <CarouselSection
            key={section.id}
            section={sectionWithTitle}
            index={index}
            dispensaryState={dispensaryState}
            hideSpecials={hideSpecials}
            showRecentlyViewedCarousel={showRecentlyViewedCarousel}
          />
        );
      })}
    </Sections>
  );
});

const Sections = styled.div``;
