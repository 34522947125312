import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GqlMenuSection } from 'types/graphql';

import usePaths from 'src/hooks/use-paths';
import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { ProductCarousel as ProductCarouselV2 } from 'src/components/carousel-v2/types/product-carousel';
import { useCollectionCarousel } from './use-collection-carousel';

export type CollectionCarouselProps = {
  section?: GqlMenuSection;
  sectionIndex?: number;
};

export function CollectionCarousel(props: CollectionCarouselProps): JSX.Element | null {
  const { section } = props;
  const { collectionId, label: sectionLabel, sortBy } = section ?? {};
  const flags = useFlags();
  const { href } = usePaths();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;
  const isCollectionsEnabled = flags['growth.ecomm.product-collections.rollout'] ?? false;
  const label = 'Collection Products';

  const carouselProps = useCollectionCarousel({
    collectionId: collectionId ?? '',
    sortBy: sortBy ?? '',
  });

  const { products, loading } = carouselProps;

  if ((!loading && !products.length) || !isCollectionsEnabled) {
    return null;
  }

  const collectionHref = `${href}/collections/${collectionId ?? ''}`;

  if (isProductCardsV2Enabled) {
    return <ProductCarouselV2 products={products} isLoading={loading} title={sectionLabel} href={collectionHref} />;
  }

  return (
    <ProductCarousel
      heading={sectionLabel ?? label}
      trackerSection={ProductRecommendationSection.collectionItems}
      {...carouselProps}
    />
  );
}
