import { ProductCarouselProps } from 'src/components/product-carousel';
import { parseProduct } from 'src/utils/helpers/product';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';

type UseCollectionCarouselParams = {
  collectionId: string;
  sortBy: string;
};

type UseCollectionCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

export function useCollectionCarousel({
  collectionId,
  sortBy,
}: UseCollectionCarouselParams): UseCollectionCarouselReturn {
  const { products, loading } = useDispensaryProducts({
    filters: { collectionId, sortBy },
    perPage: 25,
    skip: !collectionId,
  });

  return {
    products: products.map((product) => parseProduct(product)),
    loading,
  };
}
