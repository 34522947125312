import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isBlank } from 'underscore.string';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { HorizontalDivider } from 'components/core';
import DisclaimerText from 'src/dispensary/components/disclaimer-text';
import CategoryTilesGrid from 'components/category-tiles-grid';
import { MainContent } from 'components/skip-to-main';
import { MainDispensaryLayout } from 'components/layouts/main-dispensary-layout';
import { DesktopOnly, MobileOnly } from 'shared/components';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { getHtmlPlainText } from 'shared/utils/misc-utils';
import { validateAddressLimits } from 'src/state/actions/address';
import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';

import { BreadCrumbs } from 'src/components/bread-crumbs';
import { HomepageCarouselSections } from 'components/homepage-carousel-sections';
import CategoriesCarouselSlider from 'components/categories-carousel-slider';
import { SponsoredBrandCarousel } from 'components/carousels/ads-carousels';
import { PersonalizedProducts } from 'components/carousels/products-carousels/personalized-products-carousel';

import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';
import { useProductsForHomePage } from 'src/dispensary/hooks/use-products-for-home-page';

export default function DispensaryPage() {
  const apolloClient = useApolloClient();
  const Cart = useCart();
  const UI = useUI();
  const flags = useFlags();
  const { dispensary } = useDispensary();
  const { categories } = useDispensaryCategories();
  const router = useRouter();
  const orderType = useObserver(() => Cart.simplifiedOrderType);
  const [showCategoriesBorder, setShowCategoriesBorder] = useState(true);
  const dispensaryId = dispensary?.id;

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('home-page');

  const productsForHomePage = useProductsForHomePage({ dispensaryId });
  const { personalizedProductsQuery, sponsoredBrandQuery } = productsForHomePage;
  const personalizedProducts = personalizedProductsQuery?.data?.getPersonalizedProducts?.products;
  const sponsoredBrandProducts = sponsoredBrandQuery?.data?.getSponsoredBrand?.products;

  useEffect(() => {
    if (router?.query?.cName) {
      UI.setEmbeddedCName(router.query.cName);
    }
  }, [UI, router]);

  useEffect(() => {
    async function checkAddress() {
      if (!Cart.hasDeliveryAddress() || orderType === ORDER_TYPE_PICKUP) {
        return false;
      }
      const isWithinDeliveryBoundary = await validateAddressLimits(Cart, apolloClient, Cart.currentAddress, dispensary);
      if (orderType === ORDER_TYPE_DELIVERY && !isWithinDeliveryBoundary.success) {
        UI.openShowOutsideDeliveryAreaModal();
      }
      return isWithinDeliveryBoundary.success;
    }
    checkAddress();
  }, [orderType, Cart, dispensary, apolloClient, UI]);

  // Don't use Categories bottom border if an ads or personalization carousel is loading below it
  useEffect(() => {
    if (
      (personalizedProducts?.length > 0 && personalizationCarouselPlacement === 'secondary') ||
      sponsoredBrandProducts?.length > 0
    ) {
      setShowCategoriesBorder(false);
    }
  }, [personalizedProducts, sponsoredBrandProducts, personalizationCarouselPlacement]);

  const disclaimerIsBlank = useMemo(
    () =>
      !dispensary?.embedSettings?.disclaimerTextHtml ||
      isBlank(getHtmlPlainText(dispensary?.embedSettings?.disclaimerTextHtml, window?.DOMParser)),
    [dispensary?.embedSettings?.disclaimerTextHtml]
  );

  if (!dispensary) {
    return null;
  }

  return (
    <MainContent>
      <ContentWrapper>
        <BreadCrumbsContainer>
          <BreadCrumbs includeLeftPadding={false} />
        </BreadCrumbsContainer>

        {personalizationCarouselPlacement === 'primary' && <PersonalizedProducts {...productsForHomePage} />}

        <div data-testid='homepage-categories'>
          <MobileOnly mediaQuery='xs' display='block'>
            <CategoryTilesGrid categories={categories} mb='40px' />
            {showCategoriesBorder && <HorizontalDivider />}
          </MobileOnly>

          <DesktopOnly mediaQuery='sm'>
            <CategoriesCarouselSlider borderBottom={showCategoriesBorder} />
          </DesktopOnly>
        </div>

        {personalizationCarouselPlacement === 'secondary' && <PersonalizedProducts {...productsForHomePage} />}

        {hasValidSponsoredBrandPlacement && (
          <SponsoredBrandCarousel sponsoredBrandQuery={sponsoredBrandQuery} fullWidth />
        )}

        <HomepageCarouselSections dispensary={dispensary} />

        {!disclaimerIsBlank && <DisclaimerText m='50px auto' html={dispensary.embedSettings.disclaimerTextHtml} />}
      </ContentWrapper>
    </MainContent>
  );
}

DispensaryPage.layout = MainDispensaryLayout;

const ContentWrapper = styled.div`
  margin-top: 20px;
`;

const BreadCrumbsContainer = styled.div`
  margin: 0 auto 16px;

  /* Mimic sizing from carousels/category tiles above */
  @media (min-width: 600px) {
    max-width: 534.5px;
  }

  @media (min-width: 750.5px) {
    max-width: 750.5px;
  }

  @media (min-width: 966.5px) {
    max-width: 966.5px;
  }

  @media (min-width: 1187px) {
    max-width: 1187px;
  }
`;
