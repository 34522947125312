import { useMemo } from 'react';
import { ApolloError } from 'apollo-client';

import { useGetSponsoredBrandQuery } from 'types/graphql';

import useViewportVisibility, { ViewportVisibility } from 'src/hooks/use-viewport-visibility';
import { Product } from 'src/utils/helpers/product';

import { useSponsoredBrandData, useSponsoredBrandHandlers } from 'src/utils/ads/hooks/sponsored-brand';
import { getMostCommonBrandSlug } from 'src/utils/ads/helpers';

export type UseSponsoredBrandProductsParams = {
  sponsoredBrandQuery: Pick<ReturnType<typeof useGetSponsoredBrandQuery>, 'data' | 'error' | 'loading'> | null;
};

export type UseSponsoredBrandProductsReturn = {
  error: ApolloError | undefined;
  handleProductClick: (product: Product, index: number) => void;
  handleProductImpression: (product: Product) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleViewAllClick: () => void;
  loading: boolean;
  products: Product[];
  viewportVisibility: ViewportVisibility;
  brandName: string | undefined;
  brandSlug: string;
};

export function useSponsoredBrandProductsV2({
  sponsoredBrandQuery,
}: UseSponsoredBrandProductsParams): UseSponsoredBrandProductsReturn {
  const { data, loading, error } = sponsoredBrandQuery ?? {
    data: null,
    loading: false,
    error: undefined,
  };

  const viewportVisibility = useViewportVisibility();
  const { hasBeenVisible } = viewportVisibility;

  const { brandName, campaignId, globalTrackers, products } = useSponsoredBrandData(data);

  const {
    handleProductClick,
    handleProductImpression,
    handleViewAllClick,
    handleQuickAddToCart,
  } = useSponsoredBrandHandlers({
    brandName,
    campaignId,
    globalTrackers,
    products,
    hasBeenVisible,
    placement: 'category-page-sponsored-brand-carousel',
  });

  const brandSlug = useMemo(() => getMostCommonBrandSlug(products), [products]);

  return {
    brandName,
    products,
    loading,
    error,
    handleProductClick,
    handleQuickAddToCart,
    handleViewAllClick,
    handleProductImpression,
    viewportVisibility,
    brandSlug,
  };
}
